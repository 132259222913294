//

// https://animista.net/

$sho-correct: #10fa38;
$sho-incorrect: #fa1010;
// easing

$flip-ease: 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
$blink-ease: 0.5s step-end 4;
// background fade

.fade-bg-enter-active,
.fade-bg-leave-active {
    transition: opacity 2500ms;
}
.fade-bg-enter,
.fade-bg-leave-to {
    opacity: 0;
}

// page fade
.fade-enter-active,
.fade-leave-active {
    transition: opacity 500ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

//

.fade-in {
    animation: fadein 1.5s;
}

.fade-in-bg {
    animation: fadein 3s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes blink-correct {
    50% {
        border-color: darken($sho-correct, 10%);
    }
}

@keyframes blink-incorrect {
    50% {
        border-color: darken($sho-incorrect, 10%);
    }
}

// flip-down

.flip-down {
    img {
        transform: scaleY(-1);
    }
    animation: flip-down $flip-ease;
}

@keyframes flip-down {
    0% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(-180deg);
    }
}

// flip-up

.flip-up {
    img {
        transform: scaleY(-1);
    }
    animation: flip-up $flip-ease;
}
@-webkit-keyframes flip-up {
    0% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(180deg);
    }
}
@keyframes flip-up {
    0% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(180deg);
    }
}

// flip-left

.flip-left {
    img {
        transform: scaleX(-1);
    }
    animation: flip-left $flip-ease;
}

@-webkit-keyframes flip-left {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(-180deg);
    }
}
@keyframes flip-left {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(-180deg);
    }
}

// flip-right

.flip-right {
    img {
        transform: scaleX(-1);
    }
    animation: flip-right $flip-ease;
}
@-webkit-keyframes flip-right {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(180deg);
    }
}
@keyframes flip-right {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(180deg);
    }
}

// tile flip direction and return
.tile {
    &.none {
        animation: none !important;
    }
    &:nth-child(1) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-right $flip-ease;
        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(2) {
        &.none {
            img {
                transform: scaleY(1);
            }
        }
        img {
            transform: scaleY(-1);
        }
        animation: flip-down $flip-ease;

        &.flip {
            img {
                transform: scaleY(-1);
            }
            animation: flip-up $flip-ease;
            &.over {
                img {
                    transform: scaleY(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleY(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(3) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-left $flip-ease;

        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(4) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-left $flip-ease;

        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(5) {
        &.none {
            img {
                transform: scaleY(1);
            }
        }
        img {
            transform: scaleY(-1);
        }
        animation: flip-up $flip-ease;

        &.flip {
            img {
                transform: scaleY(-1);
            }
            animation: flip-down 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleY(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleY(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(6) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-right $flip-ease;
        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(7) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-right $flip-ease;

        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(8) {
        &.none {
            img {
                transform: scaleY(1);
            }
        }
        img {
            transform: scaleY(-1);
        }
        animation: flip-up $flip-ease;

        &.flip {
            img {
                transform: scaleY(-1);
            }
            animation: flip-down 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleY(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleY(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(9) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-left $flip-ease;

        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(10) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-right $flip-ease;

        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(11) {
        &.none {
            img {
                transform: scaleY(1);
            }
        }
        img {
            transform: scaleY(-1);
        }
        animation: flip-up $flip-ease;

        &.flip {
            img {
                transform: scaleY(-1);
            }
            animation: flip-down 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleY(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleY(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
    &:nth-child(12) {
        &.none {
            img {
                transform: scaleX(1);
            }
        }
        img {
            transform: scaleX(-1);
        }
        animation: flip-left $flip-ease;

        &.flip {
            img {
                transform: scaleX(-1);
            }
            animation: flip-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                both;
            &.over {
                img {
                    transform: scaleX(1);
                }
            }
        }
        &.blink {
            img {
                transform: scaleX(1);
            }
            &.correct {
                animation: blink-correct $blink-ease;
            }
            &.incorrect {
                animation: blink-incorrect $blink-ease;
            }
        }
    }
}
