// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Variables
@import 'animations';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Tailwind
@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';
//
#app {
    user-select: none;
    background-color: black;
    // height: 685px; // temp for sizing
}

body {
    font-size: 16px !important; // idk ?
}

img {
    -webkit-user-drag: none;
}

.text-show-red {
    color: var(--sho-red);
}

.text-show-teal {
    color: var(--sho-teal);
}

.bg-sho-red {
    background: var(--sho-red)
}

// layout
.screen {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 2.5fr auto;
    position: absolute;
    // width: 100%;
    // height: 100%;
}

.main-screen {
    background: black;
    background-size: cover;
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-height: 175px;
    }
    &.howto {
        max-height: 125px;
    }
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        margin: 0 auto;
    }
    p {
        // display: flex;
        // flex-direction: column;
        padding: 4rem 12rem;
        font-size: 2.3rem;
        line-height: 1.25;
        color: rgb(0, 0, 0);
    }
}

// footer {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     @media (max-width: 768px) {
//         flex-direction: column;
//     }
// }
span {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width: 100%;
}

// button
button {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 53px;
    padding-top: 5px;
    margin: 1rem 2rem;
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    background: var(--sho-red);
    @media (max-width: 1280px) {
        font-size: 2rem;
    }
    @media (max-width: 1024px) and (min-width: 768px) {
        font-size: 1.8rem;
    }
    @media (max-width: 768px) {
        width: 100% !important;
    }
    &::after {
        content: ' ';
        width: 24px;
        height: 24px;
        margin: -0.5rem 0 0 -2rem;
        background-image: url('/imgs/icon-arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        @media (max-width: 1024px) and (min-width: 768px) {
            font-size: 1.8rem;
            width: 18px;
            height: 18px;
            margin: -0.5rem 0 0 0;
        }
    }
    &.level1 {
        background: var(--sho-level1);
    }
    &.level2 {
        background: var(--sho-level2);
    }
    &.level3 {
        background: var(--sho-level3);
    }
}

.break {
    display: block;
    height: 1.25rem;
    width: 100%;
}

.disabled {
    pointer-events: none;
}

//
@media (min-width: 640px) {
    .container {
        max-width: 600px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 750px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 950px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1150px;
    }
}

.masthead__copy,
.masthead__title {
    padding-left: 0;
    padding-right: 0;
}

.masthead__copy {
    font-size: 20px;
}

.primary-nav__logo img {
    width: 110px;
    height: auto;
    padding-top: 4px;
}

// @media (max-height: 750px) {
//     // .container {
//     //     min-height: 575px;
//     // }
// }